<template>
  <div>
    <div v-if="!loaded">
      <flixLoader />
    </div>
    <transition name="flixFadeIn">
      <div
        class="flix-container-fluid"
        style="margin-top: 20px"
        :key="save"
        v-if="save"
      >
        <div class="flix-alert flix-alert-success">
          {{ $t('message.successfullSaved') }}
        </div>
      </div>
    </transition>
    <transition name="flixFadeIn">
      <div
        class="flix-bg-darken"
        :key="save"
        v-if="!save && loaded && getShowDate()"
      >
        <div class="flix-container-fluid">
          <h4 class="flix-html-h2">
            {{ $tc('message.openAppointments', Object.keys(openDates).length) }}
          </h4>
          <div class="flix-form-group" v-if="Object.keys(openDates).length > 1">
            <a
              href="#"
              class="flix-btn flix-btn-default flix-btn-xs"
              @click.prevent="setIndex(-1)"
              >&laquo;</a
            >
            {{ show + 1 }}/{{ Object.keys(openDates).length }}
            <a
              href="#"
              class="flix-btn flix-btn-default flix-btn-xs"
              @click.prevent="setIndex(1)"
              >&raquo;</a
            >
          </div>
          <transition :name="'changeDate-' + direction">
            <div class="flix-row" :key="show">
              <div class="flix-col-md-8">
                <booking :entry="getShowDate()[1]" />
              </div>
              <div class="flix-col-md-4">
                <div class="flix-text-center" style="border: 1px solid grey">
                  <div
                    style="padding: 10px"
                    class="flix-bg-warning flix-text-warning"
                  >
                    <h3
                      class="flix-html-h3 flix-text-warning"
                      style="margin: 0"
                    >
                      {{
                        $store.getters.assistentsByID[getShowDate()[1].form]
                          .title
                      }}
                    </h3>
                  </div>
                  <div style="padding: 10px">
                    <b-flix-calendarpicker
                      color="#F9A825"
                      style="background: transparent; margin: 0 auto"
                      :values="getDateValues(getShowDate()[1])"
                      :value="
                        $createDate(getShowDate()[1].begin).getFullYear() +
                        '-' +
                        ($createDate(getShowDate()[1].begin).getMonth() + 1) +
                        '-1 00:00:00'
                      "
                      mode="fix"
                      :settings="{
                        hover: true,
                        shadow: false,
                        navigation: false,
                        style: 'rounded'
                      }"
                      @save="
                        (date) => {
                          getSetDate(date, getShowDate()[1])
                        }
                      "
                    >
                    </b-flix-calendarpicker>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    booking() {
      return import('@/components/booking/')
    }
  },
  props: {
    filter: [String, Number]
  },
  data() {
    return {
      openDates: {},
      show: 0,
      direction: '',
      loaded: false,
      variables: this.$getUserVariables(),
      save: false
    }
  },
  watch: {
    '$store.state.bookings.bookingHash'() {
      this.save = true
      setTimeout(
        function () {
          this.save = false
          this.getLoadDates()
        }.bind(this),
        3000
      )
    }
  },
  mounted() {
    this.getLoadDates()
  },
  methods: {
    getDateValues(date) {
      const begin = this.$createDate(date.begin)
      const end = this.$createDate(date.end)

      let days_between = end.getTime() - begin.getTime()
      days_between = Math.floor(days_between / (60 * 60 * 24 * 1000))

      const save = []
      save.push(
        begin.getFullYear() +
          '-' +
          (begin.getMonth() + 1) +
          '-' +
          begin.getDate() +
          ' 00:00:00'
      )
      for (let i = 1; i <= days_between; i++) {
        begin.setDate(begin.getDate() + 1)
        save.push(
          begin.getFullYear() +
            '-' +
            (begin.getMonth() + 1) +
            '-' +
            begin.getDate() +
            ' 00:00:00'
        )
      }

      return save
    },
    getLoadDates() {
      this.loaded = false
      this.getOpenDates(
        function () {
          this.loaded = true
        }.bind(this)
      )
    },
    setIndex(add) {
      if (add === 1) {
        this.direction = 'next'
      } else {
        this.direction = 'before'
      }

      this.show = this.show + add

      if (this.show === Object.keys(this.openDates).length) {
        this.show = 0
      }
      if (this.show < 0) {
        this.show = Object.keys(this.openDates).length - 1
      }
    },
    getShowDate() {
      return this.openDates[Object.keys(this.openDates)[this.show]]
    },
    getSetDate(d, entry) {
      const date = new Date(d.date + ' 00:00:00')
      this.$store.commit('setCalendarDate', date)
      var name = 'specificBookingCalendar'
      if (this.$getUserVariables().state === 'share') {
        name = 'sharesSpecificBookingCalendar'
      }
      this.$router.push({
        name: name,
        params: {
          id: entry.form,
          date:
            date.getFullYear() +
            '-' +
            this.$getNullsBefore(date.getMonth() + 1) +
            '-' +
            this.$getNullsBefore(date.getDate())
        }
      })
    },
    getOpenDates(callback) {
      this.$flix_post({
        url: 'booking/open',
        data: {
          user: this.variables.user.md5_id
        },
        callback: function (ret) {
          if (!ret.data[0]) {
            this.loaded = true
            this.openDates = {}
          } else {
            this.openDates = ret.data[1]
            Object.keys(this.openDates).forEach(
              function (d) {
                this.openDates[d][0] = this.$createDate(this.openDates[d][0])
              }.bind(this)
            )
            this.loaded = true
          }
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>

$delay: 0.3s

.changeDate-next-enter-active
  animation: changeDateAnimation $delay ease-out
  transform-origin: top left
  animation-delay: $delay

.changeDate-before-enter-active
  animation: changeDateAnimation $delay ease-out
  transform-origin: top right
  animation-delay: $delay

.changeDate-next-leave-active
  animation: changeDateAnimation $delay ease-in reverse
  transform-origin: top right

.changeDate-before-leave-active
  animation: changeDateAnimation $delay ease-in reverse
  transform-origin: top left

@keyframes changeDateAnimation
  from
    transform: scaleX(0)
    opacity: 0.5
  to
    transform: scaleX(1)
    opacity: 1
</style>
