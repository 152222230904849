<template>
  <div v-if="getShowDate()">
    <transition name="zoomIn">
      <div :key="click">
        <a
          href="#"
          v-if="!click"
          @click.prevent="click = 1"
          style="text-decoration: none"
          class="flix-blinky flix-alert flix-alert-warning"
          >{{ Object.keys(openDates).length }}
          {{
            $tc('message.openAppointments', Object.keys(openDates).length)
          }}</a
        >
        <component v-if="click" :is="'openAppointments'" />
      </div>
    </transition>
  </div>
</template>
<script>
import openAppointments from '@/components/overview/openAppointments'
export default {
  extends: openAppointments,
  data() {
    return {
      click: false
    }
  },
  components: { openAppointments }
}
</script>
<style lang="sass" scoped>

$delay: .4s

.zoomIn-enter-active
  animation: zoomIn $delay ease-out
  transform-origin: top left

.flix-blinky
  animation-duration: $delay
  animation-name: blinky
  animation-timing-function: ease-in-out
  animation-iteration-count: infinite
  animation-direction: alternate
  font-weight: bold

@keyframes zoomIn
  from
    transform: scaleY(0)
    opacity: 0.5
  to
    transform: scaleY(1)
    opacity: 1

@keyframes blinky
  from
    opacity: 0.5
  to
    opacity: 1
</style>
